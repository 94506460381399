<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('proficiency_requests')"
				        @filter-div-status="datatable.filterStatus=$event">
				</Header>
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile :title="$t('proficiency_requests')"
				              @filter-div-status="datatable.filterStatus=$event">
				</HeaderMobile>
			</template>
			<datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
				<b-row>
					<b-col sm="6" md="4" lg="3" xl="2">
						<b-form-group :label="$t('student_number')">
							<b-form-input type="number" size="sm" min="0" v-check-min-max-value v-model="datatable.queryParams.filter.student_number"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col sm="6" md="4" lg="3" xl="2">
						<b-form-group :label="$t('national_number')">
							<b-form-input type="number" size="sm" min="0" v-check-min-max-value v-model="datatable.queryParams.filter.national_id"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col sm="6" md="4" lg="3" xl="2">
						<b-form-group :label="$t('name')">
							<b-form-input size="sm" v-model="datatable.queryParams.filter.name"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col sm="6" md="4" lg="3" xl="2">
						<b-form-group :label="$t('surname')">
							<b-form-input size="sm" v-model="datatable.queryParams.filter.surname"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col sm="6" md="4" lg="3" xl="2">
						<b-form-group :label="$t('registration_status')">
							<yes-no-selectbox v-model="datatable.queryParams.filter.registration_status"></yes-no-selectbox>
						</b-form-group>
					</b-col>
					<b-col sm="6" md="4" lg="3" xl="2">
						<b-form-group :label="$t('approval_status')">
							<approved-declined-selectbox v-model="datatable.queryParams.filter.english_proficiency_certificate_status"></approved-declined-selectbox>
						</b-form-group>
					</b-col>
				</b-row>
			</datatable-filter>
			<datatable :isLoading.sync="datatable.isLoading"
			           :columns="datatable.columns"
			           :rows="datatable.rows"
			           :total="datatable.total"
			           :queryParams="datatable.queryParams"
			           :lineNumbers="false"
			           @on-page-change="onPageChange"
			           @on-sort-change="onSortChange"
			           @on-per-page-change="onPerPageChange"
			           @on-cell-click="onRowClick"
			           v-show="datatable.showTable"
			>
			</datatable>
		</app-layout>
	</div>
</template>
<script>
	import AppLayout from "@/layouts/AppLayout"
	import Header from "@/layouts/AppLayout/Header"
	import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
	import DatatableFilter from "@/components/datatable/DatatableFilter";
	import Datatable from "@/components/datatable/Datatable";
	import RegistrationService from "@/services/RegistrationService";
	import qs from 'qs'
	import ApprovedDeclinedSelectbox from "@/components/interactive-fields/ApprovedDeclinedSelectbox";
	import YesNoSelectbox from "@/components/interactive-fields/YesNoSelectbox";

	export default {
		components: {
			ApprovedDeclinedSelectbox,
			AppLayout,
			Header,
			HeaderMobile,
			DatatableFilter,
			Datatable,
			YesNoSelectbox
		},
		metaInfo() {
			return {
				title: this.$t('proficiency_requests')
			}
		},
		data() {
			return {
				datatable: {
					isLoading: false,
					columns: [
						{
							label: this.$t('student_number'),
							field: 'student_number',
							sortable: false,
						},
						{
							label: this.$t('national_number'),
							field: 'national_id',
							sortable: false,
						},
						{
							label: this.$t('name'),
							field: 'name',
						},
						{
							label: this.$t('surname'),
							field: 'surname',
						},
						{
							label: this.$t('faculty'),
							field: 'faculty_name',
							sortable: false,
						},
						{
							label: this.$t('program'),
							field: 'program_name',
						},
						{
							label: this.$t('registration_type'),
							field: 'type_name',
							tdClass: 'text-center',
						},
						{
							label: this.$t('payment_status'),
							field: this.$i18n.locale == 'tr' ? 'payment_status_text' : 'payment_status_text_en',
							tdClass: 'text-center',
						},
						{
							label: this.$t('registration_status'),
							field: this.$i18n.locale == 'tr' ? 'registration_status_text' : 'registration_status_text_en',
							tdClass: 'text-center',
						},
						{
							label: this.$t('proficiency_certificate_type'),
							field: this.getLocaleField('english_proficiency_certificate_type_name'),
						},
						{
							label: this.$t('approval_status'),
							field: 'english_proficiency_certificate_status',
							formatFn: this.translate
						}
					],
					rows: [],
					total: 0,
					filterStatus: true,
					showTable: false,
					queryParams: {
						filter: {
							english_proficiency_certificate_status: 'pending',
							registration_status: 'H'
						},
						sort: 'name',
						page: 1,
						limit: 20
					}
				}
			}
		},
		methods: {
			filter() {
                this.datatable.queryParams.page = 1;
				this.getRows();
			},
			filterClear() {
				this.datatable.queryParams.filter = {};
				this.getRows();
			},
			onPageChange(params) {
				this.datatable.queryParams.page = params.currentPage;
				this.getRows();
			},
			onPerPageChange(params) {
				this.datatable.queryParams.limit = params.currentPerPage;
				this.datatable.queryParams.page = 1;
				this.getRows();
			},
			onSortChange(params) {
				const sortType = params[0].type == 'desc' ? '-' : '';
				this.datatable.queryParams.sort = sortType + params[0].field
				this.getRows();
			},
			getRows() {
				this.datatable.showTable = true;
				this.datatable.isLoading = true;

				const config = {
					params: {
						...this.datatable.queryParams
					},
					paramsSerializer: (params) => qs.stringify(params, {encode: false})
				};

				return RegistrationService.getProficiency(config)
				                          .then((response) => {
					                          this.datatable.rows = response.data.data
					                          this.datatable.total = response.data.pagination.total
				                          })
				                          .finally(() => {
					                          this.datatable.isLoading = false;
				                          });
			},
			onRowClick(event) {
				this.$router.push('/registrations/proficiency/' + event.row.id);
			}
		}
	}
</script>

